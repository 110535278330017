<template>
    <div class="logo">
        <img :src="websiteLogo" alt="Logo"/>
        <span>新易科技</span>
    </div>
</template>

<script>
export default {
  name: 'WebLogo',
  props: {
    websiteLogo: {
      type: String,
      default: function () {
        return require('@/assets/images/logo/logo.svg')
      }
    }
  }
}
</script>
