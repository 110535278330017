<template>
  <div>
    <Header :designWidth="1920"/>
    <main id="main-contain" class="page-wrapper">
      <div class="main-content">
        <slot></slot>
      </div>
      <Footer/>
    </main>
  </div>
</template>

<script>
import Header from '@/components/common/header/Header.vue';
import Footer from '@/components/common/footer/Footer.vue';
import '../../assets/font/font.less'

export default {
  name: 'Layout',
  props: {
    options: Object // 设计稿宽度和高度
  },
  components: {
    Header, Footer
  },
  data () {
    return {
      width: 0
    }
  },
  mounted () {
    this.init();
    this.updateScale();
    window.addEventListener('resize', () => {
      this.init();
    });
  },
  beforeUnmount () {
    window.removeEventListener('resize', () => {
      this.init();
    });
  },
  methods: {
    init () {
      this.width = this.options.width;
      this.updateScale();
    },
    updateScale () {
      const currentWidth = document.body.clientWidth;
      const realWidth = this.width;
      const widthScale = currentWidth / realWidth;
      document.getElementById('main-contain').style.transform = `scale(${widthScale}, ${widthScale})`;
      // console.log(window.innerWidth, document.body.clientWidth);
    }
  }
}
</script>

<style lang="less" scoped>
  .page-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    transform-origin: left top;
    font-family: "AlibabaPuHuiTiRegular",serif;
  }
</style>
