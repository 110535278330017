<template>
    <Layout :options="{width:1920, height:1080}">
        <router-view/>
    </Layout>
</template>

<script>
import Layout from '@/components/common/Layout.vue'
export default {
  components: { Layout }
}
</script>
