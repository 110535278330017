import { createRouter, createWebHashHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'

const routes = [
  {
    path: '/',
    name: 'homePage',
    component: HomePage
  },
  {
    path: '/smartFactory',
    name: 'smartFactory',
    component: () => import('../views/SmartFactory.vue')
  },
  {
    path: '/smartFactoryFirst',
    name: 'smartFactoryFirst',
    component: () => import('../views/SmartFactoryFirst.vue')
  },
  {
    path: '/smartFactorySecond',
    name: 'smartFactorySecond',
    component: () => import('../views/SmartFactorySecond.vue')
  },
  {
    path: '/smartOffice',
    name: 'smartOffice',
    component: () => import('../views/SmartOffice.vue')
  },
  {
    path: '/smartOfficePDM',
    name: 'smartOfficePDM',
    component: () => import('../views/smartOfficePDM.vue')
  },
  {
    path: '/smartOfficePMS',
    name: 'smartOfficePMS',
    component: () => import('../views/smartOfficePMS.vue')
  },
  {
    path: '/digitalDesign',
    name: 'digitalDesign',
    component: () => import('../views/DigitalDesign.vue')
  },
  {
    path: '/digitalView',
    name: 'digitalView',
    component: () => import('../views/DigitalView.vue')
  },
  {
    path: '/web3D',
    name: 'web3D',
    component: () => import('../views/web3D.vue')
  },
  {
    path: '/cooperationService',
    name: 'cooperationService',
    component: () => import('../views/CooperationService.vue')
  },
  {
    path: '/EAP',
    name: 'EAP',
    component: () => import('../views/EAP.vue')
  },
  {
    path: '/MES',
    name: 'MES',
    component: () => import('../views/MES.vue')
  },
  {
    path: '/WMS',
    name: 'WMS',
    component: () => import('../views/WMS.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: 'active', // 定义路由选中后的样式，active为router-link点击后的类名
  routes
})

export default router
