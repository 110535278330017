<template>
  <div ref="page">
    <div class="bg_image">
      <!-- <div class="contact" @click=""></div> -->
      <div class="contact" @click="jumpToContact">
        <div class="contact_text">联系我们</div>
        <div class="contact_arrow">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" viewBox="0 0 22 18" fill="none">
            <path d="M18.6714 9.02075L1.87891 9.02075" stroke="#327CFE" stroke-width="2" stroke-linejoin="round" />
            <path
              d="M14.252 4.63916L18.4895 8.14063C18.6045 8.23562 18.671 8.37695 18.671 8.52607V9.51573C18.671 9.66485 18.6045 9.80619 18.4895 9.90118L14.252 13.4026"
              stroke="#327CFE" stroke-width="2" stroke-linejoin="round" />
          </svg>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="service">
        <div class="title">服务能力</div>
        <div class="sub_title">设备控制、生产控制系统、研发管理系统、可视化系统等行业应用定制开发</div>
        <div class="img_content">
          <div class="img" v-for="(item, idx) in img_idx.text" :class="'img' + img_idx.id[idx]" :key="idx">
            <div class="img_text">{{ item }}</div>
            <div class="overlay">
              <h2 style="width: 70%;" v-if="idx == 0">
                智慧工厂是智能化、自动化和灵活性的现代化工厂，实现了生产管理等各个环节的数字化、网络化和智能化，提高生产效率、降低成本、满足不断变化的市场需求。</h2>
              <h2 style="width: 70%;" v-if="idx == 1">
                智慧办公是应用信息技术和物联网技术，将传统的办公环境转变为智能化、数字化和高效化的办公模式。智慧办公借助先进的技术手段，提供更智能、便捷、灵活和高效的办公方式，使员工更加舒适、工作更加高效，并提升整个办公环境的效率和可持续发展
              </h2>
              <h2 style="width: 70%;" v-if="idx == 2">
                数字设计通过计算机和数字技术进行的设计过程，用于创建各种数字产品、图形、界面、动画。涵盖了多个领域，包括计算机图形学、界面设计、虚拟现实、用户体验设计等。</h2>
            </div>
          </div>

        </div>
      </div>
      <div class="tech">
        <div class="title">技术优势</div>
        <div class="sub_title">提供专业的解决方案服务，量身定制契合业务需求的高性价比的服务方案</div>
        <div class="sub_menu">
          <div class="sub_item" :class="{ active: show_idx === 1 }" @click="factoryTab">智慧工厂</div>
          <div class="sub_item" :class="{ active: show_idx === 2 }" @click="officeTab">智慧办公</div>
          <div class="sub_item" :class="{ active: show_idx === 3 }" @click="designTab">数字设计</div>
        </div>
        <div class="line"></div>
        <div class="sub_content">
          <div class="sub_tab">
            <img class="sub_icon" src="../assets/images/homepage/icon1.png" alt="">
            <div class="sub_tab_title">{{ subTabTitle[0] }}</div>
            <div class="sub_tab_text">{{ subTabText[0] }}</div>
          </div>
          <div class="sub_tab">
            <img class="sub_icon" src="../assets/images/homepage/icon2.png" alt="">
            <div class="sub_tab_title">{{ subTabTitle[1] }}</div>
            <div class="sub_tab_text">{{ subTabText[1] }}</div>
          </div>
          <div class="sub_tab">
            <img class="sub_icon" src="../assets/images/homepage/icon3.png" alt="">
            <div class="sub_tab_title">{{ subTabTitle[2] }}</div>
            <div class="sub_tab_text">{{ subTabText[2] }}</div>
          </div>
          <div class="sub_tab" v-if="subTabTitle[3] != null">
            <img class=" sub_icon" src="../assets/images/homepage/icon4.png" alt="">
            <div class="sub_tab_title">{{ subTabTitle[3] }}</div>
            <div class="sub_tab_text">{{ subTabText[3] }}</div>
          </div>
        </div>
      </div>
      <div class="team">
        <div class="title">团队介绍</div>
        <div class="sub_title" style="color: #5B5B5B; font-weight: 550">携手专业团队伙伴，持续推进服务业务与技术栈发展</div>
        <div class="plan_team">
          <div class="team_title_line_up"></div>
          <div class="team_title">{{ teamTitle }}</div>
          <div class="team_title_line_dn"></div>
          <div class="team_sub_title">{{ teamSubTitle }}</div>
          <div class="team_text">{{ teamText }}</div>
          <div :class="show_team_idx === 1 ? 'switch_dot_1' : show_team_idx === 2 ? 'switch_dot_2' : 'switch_dot_3'">
          </div>
          <div class="team_tab">
            <div class="team_icon" :class="show_team_idx === 1 ? 'team1_sel' : 'team1'" @click="showPlanTeam">
              <div :class="show_team_idx === 1 ? 'team_icon_text_sel' : 'team_icon_text'">解决方案</div>
            </div>
            <div class="team_icon" :class="show_team_idx === 2 ? 'team2_sel' : 'team2'" @click="showCodeTeam">
              <div :class="show_team_idx === 2 ? 'team_icon_text_sel' : 'team_icon_text'">程序开发</div>
            </div>
            <div class="team_icon" :class="show_team_idx === 3 ? 'team3_sel' : 'team3'" @click="showDesignTeam">
              <div :class="show_team_idx === 3 ? 'team_icon_text_sel' : 'team_icon_text'">数字设计</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  components: {
  },
  data() {
    return {
      show_idx: 1,
      img_idx: {
        id: ['1', '2', '3'],
        text: ['智慧工厂', '智慧办公', '数字设计']
      },
      subTabTitle: ['数字化管理', '资源全面的整合', '分析和预测能力', '提升效率和生产力'],
      subTabText: ['智慧工厂为芯片制造企业的生产车间提供闭环式的数字化管理，满足企业的工艺管理、制程管理、防呆防错、质量管控、资源管理、追溯分析、协同指挥、提高产能、实时数据采集等关键需求。', '企业可以依照行业特性、管理方式或生产模式等不同，进行信息化需求整合，通过灵活配置以满足个性化需求，使管理者能够准确实时地掌控工厂生产制程的状况', '通过数据采集以及大数据处理来实现分析和诊断，并且推断未来发展趋势，提供有价值的参考数据。', '帮助企业缩短生产周期、提高质量、全面防错防呆及科学的可追溯管理，实现降本增效，提升运营效率，更高效地服务市场。'],
      show_team_idx: 1,
      teamTitle: '解决方案开发团队',
      teamSubTitle: '最完善的全栈技术订制',
      teamText: '一个好的解决方案团队带来的利益是多方面的，我们的团队拥有最好的整合资源能力，能极优地简化客户流程，提高了效率，带来更好的客户满意度与忠诚度；而对于选择我们团队的客户来说，一站式的解决方案更是节约了宝贵的时间，把所有的问题一次性解决，更便捷、更高效当然也更省钱。'
    }
  },
  methods: {
    factoryTab() {
      this.show_idx = 1
      this.subTabTitle[0] = '使用便捷 方便创新'
      this.subTabText[0] = '使用多种数字工具，把物理设备的属性映射到虚拟空间，减少物理实体的理解成本。'
      this.subTabTitle[1] = '更全面的测量'
      this.subTabText[1] = '全面测量包括物理实体的属性、参数和运行状态等各方面的数据，且大大提升了数据测量效率'
      this.subTabTitle[2] = '分析和预测能力'
      this.subTabText[2] = '通过数据采集以及大数据处理来实现分析和诊断，并且推断未来发展趋势，提供有价值的参考数据。'
      this.subTabTitle[3] = '提升效率和生产力'
      this.subTabText[3] = '为客户节省探索有关物理项目不同的途径来改善流程，可以从根本上在平台中运行模拟。'
    },
    officeTab() {
      this.show_idx = 2
      this.subTabTitle[0] = '数字化办公'
      this.subTabText[0] = '电子流程，摆脱传统手工管理的繁琐、低效。通过功能丰富的线上系统，助力办公流程高效流转，随时地自由回溯、追踪感兴趣环节。'
      this.subTabTitle[1] = '智能化分析'
      this.subTabText[1] = '通过系统辅助项目排期、资料管理，做到精准把控、规划，进度实时把握，效率即刻感知。系统自过滤可优化项、监督执行。'
      this.subTabTitle[2] = '团队资源调优'
      this.subTabText[2] = '团队的资源分配、规划，借助智慧办公系统可以做到快速调配、全盘感知。领导驾驶舱可以把握公司全局进程，通过效能分析工具可以自动感知资源空窗、提升人效，优化团队战斗力。'
      this.subTabTitle[3] = null
      // this.subTabText[3] = '待补充'
    },
    designTab() {
      this.show_idx = 3
      this.subTabTitle[0] = '定制化方案'
      this.subTabText[0] = '为客户定制化制定产品方案，只为更贴合客户实际需求与产业链。提供无限的设计可能性，从品牌标识到用户界面甚至于搭建场景，能够灵活应对不同的设计需求。'
      this.subTabTitle[1] = '数据驱动优化'
      this.subTabText[1] = '结合数据分析，最终产品帮助优化用户体验和营销效果，提升业务的可持续发展能力。'
      this.subTabTitle[2] = '可视化沟通'
      this.subTabText[2] = '通过搭建可视化表单、产业链全场景、数据汇总等视觉元素，帮助客户更直观地理解产品或服务，提高沟通效率。'
      this.subTabTitle[3] = '跨平台适配'
      this.subTabText[3] = '轻松适配不同的数字平台，包括web网站和移动应用，应对各个不同分辨率的也游刃有余，能够在复杂到硬件显示下达到同步的视觉元素效果。'
    },
    jumpToContact() {
      const wrapper = this.$refs.page
      window.scrollTo({ top: wrapper.scrollHeight, behavior: 'smooth' })
    },
    showPlanTeam() {
      this.show_team_idx = 1
      this.teamTitle = '解决方案开发团队'
      this.teamSubTitle = '最完善的全栈技术订制'
      this.teamText = '一个好的解决方案团队带来的利益是多方面的，我们的团队拥有最好的整合资源能力，能极优地简化客户流程，提高了效率，带来更好的客户满意度与忠诚度；而对于选择我们团队的客户来说，一站式的解决方案更是节约了宝贵的时间，把所有的问题一次性解决，更便捷、更高效当然也更省钱。'
    },
    showCodeTeam() {
      this.show_team_idx = 2
      this.teamTitle = '程序开发团队'
      this.teamSubTitle = '高效卓越的技术能力和稳定可靠的数字产品'
      this.teamText = '我们拥有一支高效、创新且充满激情的程序开发团队，致力于为客户提供卓越的技术解决方案。我们60%的开发团队成员来自知名大厂，拥有丰富的经验和专业技能。他们紧跟行业的最新趋势，掌握最新的编程语言和开发工具，确保我们的产品始终处于领先地位。我们的团队致力于自主研发，持续追求技术创新。我们程序开发团队的专业知识、创新思维和团队合作精神将为客户提供高质量的技术产品和优质的客户服务。无论您有什么需求，我们都有信心能够满足并超越您的期望。'
    },
    showDesignTeam() {
      this.show_team_idx = 3
      this.teamTitle = '数字设计开发团队'
      this.teamSubTitle = '创造性的设计和卓越的用户体验'
      this.teamText = '我们的设计师擅长将美学与功能性相结合，为客户提供独特而吸引人的设计方案。他们熟练掌握各种设计工具和软件，能够根据客户需求创建出色的UI和UX设计，我们的设计师都能够通过创新的设计思路和敏锐的触觉进行量身定制。我们的数字设计开发团队注重与客户的合作和沟通，了解客户需求，并提供专业建议和解决方案。优秀的数字设计开发团队能为众多客户提供了优质的数字产品和卓越的用户体验。'
    }
  }
}

</script>

<style lang="less" scoped>
@import "../assets/less/default/_layoutvar.less";

.content {
  width: 100%;

  .service {
    width: 100%;
    height: 1200px;
    background: #F8FAFF;

    .img_content {
      display: flex;
      justify-content: center;

      .img {
        height: 708px;
        width: 500px;
        margin: 0 25px;
        position: relative;
        overflow: hidden;
        transition: transform 0.3s ease-in-out;
        /* 添加过渡效果 */
      }

      .img:hover {
        transform: scale(1.05);
        /* 放大到1.2倍 */
      }

      .img1 {
        background-image: url(../assets/images/homepage/factory.png);
        background-size: 100% 100%;
      }

      .img2 {
        background-image: url(../assets/images/homepage/office.png);
        background-size: 100% 100%;
      }

      .img3 {
        background-image: url(../assets/images/homepage/design.png);
        background-size: 100% 100%;
      }

      .img_text {
        position: absolute;
        height: 153px;
        width: 500px;
        line-height: 153px;
        color: white;
        font-size: 42px;
        font-weight: 500;
        text-align: center;
        bottom: 32px;
        background: linear-gradient(95.99deg, rgba(255, 255, 255, 0.33) 0%, rgba(217, 217, 217, 0.26) 100%);
        backdrop-filter: blur(7px);
      }
    }
  }

  .tech {
    width: 100%;
    height: 1100px;
    background: #FFFFFF;
  }

  .team {
    width: 100%;
    height: 1250px;
    margin-top: 100px;
    background: #F8FAFF;
  }

  .title {
    text-align: center;
    color: #272727;
    font-size: 46px;
    font-weight: 500;
    padding-top: 110px;
  }

  .sub_title {
    text-align: center;
    color: #4C4C4C;
    font-size: 24px;
    font-weight: 400;
    margin-top: 30px;
    margin-bottom: 100px;
  }

  .sub_menu {
    display: flex;
    justify-content: center;
    margin-top: 120px;

    .sub_item {
      color: #272727;
      font-size: 32px;
      font-weight: 550;
      text-align: center;
      text-decoration: none;
      margin: 0 130px;
      display: block;
      height: 60px;
      width: 203px;
      border-bottom: 4px solid transparent;
      line-height: 60px;
      cursor: pointer;
      z-index: 1;

      &.active {
        color: #327CFE !important;
        border-bottom: 4px solid #327CFE !important;
      }
    }
  }

  .line {
    position: relative;
    left: 42px;
    top: -2px;
    width: 1836px;
    border: 1px solid #E1E1E1;
  }

  .sub_content {
    display: flex;
    justify-content: center;
    margin-top: 60px;

    .sub_tab {
      text-align: center;
      text-decoration: none;
      margin: 0 20px;
      display: block;
      height: 600px;
      width: 366px;
      background: linear-gradient(180deg, #F3F7FF 0%, #F7FAFF 86.26%, #F8FAFF 100%);
      border-radius: 8px;

      .sub_icon {
        height: 70px;
        width: 70px;
        margin-top: 40px;
      }

      .sub_tab_title {
        font-size: 30px;
        font-weight: 600;
        color: #272727;
        margin-top: 20px;
      }

      .sub_tab_text {
        text-align: justify;
        font-size: 24px;
        font-weight: 400;
        color: #4C4C4C;
        margin: 30px 36px;
        line-height: 46px;
      }
    }
  }

  .plan_team {
    position: relative;
    height: 916px;
    width: 1920px;
    background-image: url(../assets/images/homepage/planTeam.png);
    background-size: 100% 100%;
  }

  .team_tab {
    display: flex;
    justify-content: center;

    .team_icon {
      height: 80px;
      width: 80px;
      margin-top: 708px;
      margin-right: 280px;
      margin-left: 280px;
      cursor: pointer;
      text-align: center;
    }

    .team1 {
      background-image: url(../assets/images/homepage/plan_unsel.png);
      background-size: 100% 100%;
    }

    .team1_sel {
      background-image: url(../assets/images/homepage/plan_sel.png);
    }

    .team2 {
      background-image: url(../assets/images/homepage/code_unsel.png);
      background-size: 100% 100%;
    }

    .team2_sel {
      background-image: url(../assets/images/homepage/code_sel.png);
    }

    .team3 {
      background-image: url(../assets/images/homepage/design_unsel.png);
      background-size: 100% 100%;
    }

    .team3_sel {
      background-image: url(../assets/images/homepage/design_sel.png);
    }

    .team_icon_text_sel {
      width: 108px;
      font-size: 24px;
      font-weight: 500;
      margin-left: -14px;
      margin-top: 100px;
      color: #FFFFFF;
    }

    .team_icon_text {
      width: 108px;
      font-size: 24px;
      font-weight: 500;
      margin-left: -14px;
      margin-top: 100px;
      color: #474C59;
    }
  }

  .team_title_line_up {
    position: absolute;
    width: 478px;
    height: 2px;
    top: 110px;
    left: 721px;
    background-image: url(../assets/images/homepage/team_line.png);
    background-size: 100% 100%;
  }

  .team_title_line_dn {
    position: absolute;
    width: 478px;
    height: 2px;
    top: 215px;
    left: 721px;
    background-image: url(../assets/images/homepage/team_line.png);
    background-size: 100% 100%;
  }

  .team_title {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 130px;
    font-weight: 600;
    font-size: 42px;
    color: #F8FAFF;
  }

  .team_sub_title {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 235px;
    font-weight: 500;
    font-size: 24px;
    color: #F8FAFF;
  }

  .team_text {
    position: absolute;
    text-align: justify;
    width: 1170px;
    top: 320px;
    left: 375px;
    font-weight: 400;
    font-size: 26px;
    line-height: 55px;
    color: #F8FAFF;
  }

  .switch_dot_1 {
    position: absolute;
    width: 65px;
    height: 13px;
    top: 615px;
    left: 927.5px;
    background-image: url(../assets/images/homepage/sel_1.png);
    background-size: 100% 100%;
  }

  .switch_dot_2 {
    position: absolute;
    width: 65px;
    height: 13px;
    top: 615px;
    left: 927.5px;
    background-image: url(../assets/images/homepage/sel_2.png);
    background-size: 100% 100%;
  }

  .switch_dot_3 {
    position: absolute;
    width: 65px;
    height: 13px;
    top: 615px;
    left: 927.5px;
    background-image: url(../assets/images/homepage/sel_3.png);
    background-size: 100% 100%;
  }
}

.bg_image {
  min-height: 930px;
  min-width: 1920px;
  margin-top: -76px;
  background-image: url(../assets/images/homepage/bg.png);
  background-size: 100% 100%;
}


//遮罩显示
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: linear-gradient(96deg, rgba(255, 255, 255, 0.33) 0%, rgba(217, 217, 217, 0.26) 100%);

  backdrop-filter: blur(7px);
  /* 初始为透明 */
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  /* 初始不显示 */
  transition: opacity 0.3s ease-in-out;
  /* 过渡效果 */
}

.img:hover .overlay {
  opacity: 1;
  /* 鼠标悬停时显示 */
}

.contact {
  position: absolute;
  display: flex;
  align-items: center;
  top: 634px;
  left: 240px;
  width: 200px;
  height: 65px;
  cursor: pointer;
  transition: width 0.3s ease-in-out;
  /* 添加过渡效果 */
  transition: all 800ms ease 0s;
  border-radius: 12px;
  background: #FFF;
  // color: black;
  color: white;
}

.contact:hover {
  width: 250px;
  /* 调整为你想要的宽度 */
  color: white;
  background-color: rgba(255, 255, 255, 0.4);
}

.contact_text {
  width: 180px;
  height: 50px;
  margin-left: 9px;
  font-size: 22px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #327CFE;
  font-family: ABeeZee;
}

.contact_arrow {
  transform: translate(0, 0);
  opacity: 0;
  visibility: hidden;
  width: 0px;
  transition: transform 0.5s ease, opacity 0.5s ease;

}

.contact:hover .contact_arrow {
  transform: translate(20px, 0);
  opacity: 1;
  visibility: visible
}
</style>
