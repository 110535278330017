<template>
    <div :class="{'header-transparent-with-topbar': false}">
        <!-- Start Header Area -->
        <header id="top-header" class="header-default">
            <WebLogo/>
            <div class="header-right">
                <nav class="mainmenu-nav">
                    <NavBar/>
                </nav>
            </div>
            <div class="header-blank"></div>
        </header>
    </div>
</template>

<script>
import NavBar from './NavBar'
import WebLogo from '../../elements/logo/WebLogo'

export default {
  name: 'Header',
  props: {
    designWidth: Number // 设计稿宽度
  },
  components: { WebLogo, NavBar },
  data () {
    return {
      width: 1920
    }
  },
  mounted () {
    this.init();
    this.updateScale();
    window.addEventListener('resize', () => {
      this.init();
    });
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.toggleStickyHeader);
    window.removeEventListener('resize', () => {
      this.init();
    });
  },
  methods: {
    init () {
      this.width = this.designWidth;
      this.updateScale();
    },
    updateScale () {
      const currentWidth = document.body.clientWidth;
      const realWidth = this.width;
      const widthScale = currentWidth / realWidth;
      document.getElementById('top-header').style.transform = `scale(${widthScale}, ${widthScale})`;
    }
  }
}
</script>

<style lang="less" scoped>
  .header-default {
    top: 0;
    left: 0;
    transform-origin: left top;
    font-family: "AlibabaPuHuiTiRegular",serif;
  }
</style>
