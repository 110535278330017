<template>
  <ul class="mainmenu" >
    <router-link class="item" to="/">首页</router-link>
    <div class="itemfactory">
      <router-link class="item" to="/smartFactory"> 智慧工厂</router-link>
      <div class="drop" style="width:250px;margin-left: -5px">
        <router-link class="itemsecond" to="/smartFactoryFirst" style="font-size: 20px;"> EAP设备控制系统</router-link>
        <router-link class="itemsecond" to="/MES" style="font-size: 20px;">MES生产执行系统</router-link>
        <router-link class="itemsecond" to="/WMS" style="font-size: 20px;">WMS仓储运输系统</router-link>
        <router-link class="itemsecond" to="/smartFactorySecond" style="font-size: 20px;"> ATE自动测试平台</router-link>
      </div>
    </div>
    <div class="itemfactory">
      <router-link class="item" to="/smartOffice"> 智慧办公</router-link>
      <div class="drop" style="width: 250px;margin-left: -5px">
        <router-link class="itemsecond" to="/smartOfficePMS" style="font-size: 20px;"> PMS项目管理系统</router-link>
        <router-link class="itemsecond" to="/smartOfficePDM" style="font-size: 20px;"> PDM产品数据管理</router-link>
      </div>
    </div>
    <div class="itemfactory">
      <router-link class="item" to="/digitalDesign"> 数字设计</router-link>
      <div class="drop" style="width: 250px;margin-left: -5px">
        <router-link class="itemsecond" to="/digitalView" style="font-size: 20px;"> 数字可视化</router-link>
        <router-link class="itemsecond" to="/web3D" style="font-size: 20px;"> web3D设计</router-link>
      </div>
    </div>
    <router-link class="item" to="/cooperationService">合作与服务</router-link>
  </ul>
</template>

<script>
export default {
  name: 'NavBar'
}
</script>
<style></style>
