<template>
  <div>
    <!-- Start Footer Area -->
    <div class="footer-default">
      <div class="footer-title">
        <img src="@/assets/images/logo/logo.png" alt="Logo" />
        <span>新易南京</span>
      </div>
      <div class="split-line"></div>
      <div class="contact-txt">联系我们 &nbsp; </div>
      <div class="cooperative-txt">公司概况 </div>
      <div class="info1-txt">
        <!-- <p>X经理</p> -->
        <p>电话：13110762863</p>
        <p>邮箱：wenzhenhang@xinyi.tech</p>
        <p>地址：江苏省南京市雨花台区软件谷云密城A栋1406</p>
      </div>
      <!-- <div class="info2-txt">
        <p>X经理</p>
        <p>电话:18601230001</p>
        <p>邮箱:youxiang@163.com</p>
        <p>地址:福建省福州市XX区XX路6号</p>
      </div> -->
      <div class="circle">
        <div style="
        width: 800px;
        color: white;
font-family: Inter;
font-size: 20px;
font-weight: 500;
line-height: 2;
        ">
          新易智能工业科技(南京)有限公司，是一家聚焦半导体研发与制造企业数字化转型的技术服务企业，团队拥有多名资深数字化专家、年轻的一线实施团队。并具备AI大模型应用开发、落地部署全流程技术栈。目前已经拥有了一系列智能生产和智能办公的系统产品，建立了完善的产品研发、实施保障的服务体系。
          我们秉承“服务即产品，效果即成果”的理念，致力于以前沿技术、亲民价格，切实为科技企业提升经营生产智能化水平，为客户创造更高人效。促进工业4.0时代智能化转型。 </div>

        <!-- <img src="@/assets/images/footer/circle.svg" alt="Partner"/>
        <img src="@/assets/images/footer/circle.svg" alt="Partner"/>
        <img src="@/assets/images/footer/circle.svg" alt="Partner"/>
        <img src="@/assets/images/footer/circle.svg" alt="Partner"/>
        <img src="@/assets/images/footer/circle.svg" alt="Partner"/>
        <img src="@/assets/images/footer/circle.svg" alt="Partner"/>
        <img src="@/assets/images/footer/circle.svg" alt="Partner"/>
        <img src="@/assets/images/footer/circle.svg" alt="Partner"/> -->
      </div>
      <div class="icpInf">
        <div>新易智能工业科技（南京）有限公司</div>
        <div style="margin-top: 10px">
          <h>
            <a href="https://beian.miit.gov.cn" id="beian" target="_blank" style="color: #fff">苏ICP备2023050864号-1</a>
          </h>
          <h style="margin-left: 10px">
            <img style="vertical-align:middle" src="../../../assets/images/logo/32011402011478.png">
            <a href="//www.beian.gov.cn/portal/registerSystemInfo?recordcode=32011402011478" style="color: #fff">苏公网安备32011402011478号</a>
          </h>
<!--          <h>-->
<!--            苏ICP备2023050864号-1-->
<!--          </h>-->
<!--          <h style="margin-left: 10px">-->
<!--            苏公网安备 32011402011478号-->
<!--          </h>-->
        </div>
      </div>
    </div>
    <!-- End Footer Area -->

  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    data: {
      default: null
    }
  }
}
</script>
